'use strict';

var braintree = require('./payment/braintree');
var $refundButton = $('input[data-stor-id="refund-btn"]');
var $paymentRefundForm = $('#payment-form');
var $ccNumberField = $('#card-number');
var $ccExpField = $('#card-exp');
var $cvvField = $('#cvv');
var fieldValidatedClass = 'braintree-hosted-fields-valid';

// Toggle clickability of button
function setRefundButtonClickability() {
    var isFormReady = ($ccNumberField.hasClass(fieldValidatedClass) && 
                       $ccExpField.hasClass(fieldValidatedClass) && $cvvField.hasClass(fieldValidatedClass))
    $refundButton.prop('disabled', !isFormReady);
}

// Disable button on submit to prevent double-clicks
function disableRefundButtonOnSubmit(refundInterval) {
    $paymentRefundForm.submit(function() {
        $refundButton.prop('disabled', true);
        clearInterval(refundInterval);
    });
}

function init () {
    braintree.init();

    var refundInterval = setInterval(function(){
        setRefundButtonClickability();
    }, 1000);

    disableRefundButtonOnSubmit(refundInterval);
}

module.exports = init;
