'use strict';

// cookie expiration date in 1 year
var exp = new Date();
exp.setDate(exp.getDate() + 365);

function hideCookiesNotice() {
    $('.js-cookies-banner').slideUp();
}

// If cookie does not exist, show banner
if (Cookies.get('cookies_notice') != 'True'){
    $('.js-cookies-banner').show();
}

// Hide GB Cookie Notice after 60 Seconds
setTimeout(hideCookiesNotice, 60000);

// Hide GB Cookie Notice if you close the banner
$('.js-close-notice').click(hideCookiesNotice);

// Set cookies
Cookies.set('cookies_notice', 'True', {expires: exp, path: '/', domain: '23andme.com'});
