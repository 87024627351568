'use strict';

var $renewalRefundButton = $('.button-continue');
var $renewalRefundFormFields = $('.renewal-refund-input');
var $renewalRefundForm = $('.renewal-refund-form');

function setUpRenewalRefundButtons() {
    $renewalRefundFormFields.each(function(){
        var _this = $(this);
        _this.change(function(){
            if (_this.val() !== "") {
                $renewalRefundButton.prop('disabled', false);
            } else {
                $renewalRefundButton.prop('disabled', true);
            };
        });
    })
}

function renewalRefundAddErrorClass(formField) {
    formField.addClass('error');
    formField.prev('label').addClass('error');
}

function renewalRefundRemoveErrorClass(formField) {
    formField.removeClass('error');
    formField.prev('label').removeClass('error');
}

function validateRenewalRefundForm() {
    var isValid = true;
    $renewalRefundFormFields.each(function(){
        var _this = $(this);
        if (_this.val() === '') {
            if(isValid) {
                scrollToField(_this);
            }
            isValid = false;
            renewalRefundAddErrorClass(_this);
        } else {
            renewalRefundRemoveErrorClass(_this);
        }
    });
    return isValid;
}

function enableFormSubmission() {
    $renewalRefundButton.click(function(e){
        e.preventDefault();
        if (validateRenewalRefundForm()) {
            $renewalRefundForm.submit();
        }
    });
}

function init () {
    enableFormSubmission();
    setUpRenewalRefundButtons();
}

module.exports = init;