'use strict';

var Kit = require('./cart/kits').Kit;
var kits = [];
var EventEmitter = require('events').EventEmitter;
var eventEmitter = new EventEmitter();
var initializeVeil = require('./veil');
var slickSlideWidth = '448px';
var _ = require('lodash');


// private functions
// =================

function initializeSliders () {
    $('[data-slick]').slick();
    $('.slick-current').css('width', slickSlideWidth);
    $('.draggable').css('max-height', '143px');
}

function handleGiftWrapPricing (device) {
    if ($('.js-gift-' + device).is(':checked')) {
        var total = 3.99 * $('.js-gift-' + device + ':checked').length;
        $('.js-giftwrap-total-' + device).html(total);
        $('.js-giftwrap-details-' + device).show();
    } else {
        $('.js-giftwrap-details-' + device).hide();
    }
}

function handlePrivacyOption ($kit) {
    if ($kit.find('.js-privacy').is(':checked')) {
        $kit.find('.js-magnifying-privacy').show();
        $kit.find('.js-privacy-overlay').show();
        $kit.find('.giftwrap-default').css('opacity', '0.2');
    } else {
        $kit.find('.js-magnifying-privacy').hide();
        $kit.find('.js-privacy-overlay').hide();
        $kit.find('.giftwrap-default').css('opacity', '1');
    }
}

function handleGiftOption ($kit) {
    if ($kit.find('.js-gift').is(':checked')) {
        $kit.find('.js-slider').show();
        $kit.find('.js-giftwrap-default').hide();
        $kit.find('.js-magnifying').show();
        $kit.find('.slick-current').css('width', slickSlideWidth);
        $kit.find('[data-slick]').slick('setPosition');
    } else {
        $kit.find('.js-slider').hide();
        $kit.find('.js-giftwrap-default').show();
        $kit.find('.js-magnifying').hide();
    }
}

function handleGiftWrapOptions () {
    $('.js-radio').on('click', function(event){
        var $kit = $(this).closest('.giftwrap-kit');
        handlePrivacyOption($kit);
        handleGiftOption($kit);

        // handle gift wrap pricing display for mobile
        handleGiftWrapPricing('mobile');

        // handle gift wrap pricing display for desktop
        handleGiftWrapPricing('desktop');
    });
}

function handleMobileCheckbox () {
    // depending of state of checkbox, show or hide gift message textbox
    var $checkbox = $('.js-message-checkbox');
    $checkbox.on('click', function(event){
        var $textarea = $(event.target).parent().next();
        if ($(event.target).is(':checked')) {
            $textarea.show();
        } else {
            // empty message if checkbox is unchecked
            $textarea.val('');
            $textarea.trigger('input');
            $textarea.hide();
        }
    });
}

// on document ready
// =================

function init () {
    initializeVeil();
    initializeSliders();
    kits = _.map($('.js-kit-item, .js-ancestry-kit-item'), function(el) {
        var newKit = new Kit(el, eventEmitter);
        newKit.watchGiftOptions();
        return newKit;
    });

    // Set listener for radio buttons
    handleGiftWrapOptions();

    // Set listener for mobile giftwrap checkbox
    handleMobileCheckbox();

    // Ensure proper pricing for giftwrap is shown
    handleGiftWrapPricing('desktop');
    handleGiftWrapPricing('mobile');
}

module.exports = init;
