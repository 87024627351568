'use strict';

var _ = require('lodash');
function initDrawers() {

    // Usage
    // Trigger:
    //     <button aria-controls="drawer-one" aria-expanded="false">
    //         ...
    //     </button>

    // Alt trigger:
    //     <a href="#drawer-one" data-drawer aria-controls="drawer-one" aria-expanded="false">
    //         ...
    //     </a>

    // Element to expand:
    //     <div id="drawer-one" class="u-hide">
    //         ...
    //     </div>

    // Indicator element (elements to just apply state classes to):
    //     <div data-drawer-indicator="drawer-one">
    //         ...
    //     </div>

    var speed = 500;
    var classes = {
        'open': 'drawer-open',
        'transition': 'drawer-transition'
    };

    function toggle(ids) {
        // CASE: multiple ids
        var idList = ids.trim().split(/\s+/);

        var elSelector = idList.map(function(id) {
            return '#' + id;
        }).join(', ');

        var indicatorsSelector = idList.map(function(id) {
            return '[data-drawer-indicator="' + id + '"]';
        }).join(', ');

        var $el = $(elSelector);
        var $triggers = $('[aria-controls="' + ids + '"]');
        var $indicators = $(indicatorsSelector).add($triggers).add($el);
        var expanded = $($triggers[0]).attr('aria-expanded') === 'true'; // Just grab the first one.
        $indicators.addClass(classes.transition);

        if (expanded) {
            $el.slideUp(speed, function() {
                $triggers.attr('aria-expanded', 'false');
                $indicators.removeClass(classes.transition);
                $indicators.removeClass(classes.open);
            });
        } else {
            $el.slideDown(speed, function() {
                $triggers.attr('aria-expanded', 'true');
            });
            $indicators.addClass(classes.open);
        }
    }

    $(document).on('click', '[data-drawer]', function() {
        var ids = $(this).attr('aria-controls');
        toggle(ids);
        return false;
    });
};

function fdaModalSetUp() {
    var current_tab = 'fda-main';
    var dbtime = 10;
    var $window = $(window);
    var $fdaInfo = $('.fda-info');
    var $fdaTabs = $('.fda-tabs');
    var $checkoutHeader = $('.checkout-header');

    if (current_tab.length > 0) {
        $window.scroll(_.debounce(function() {
            var scrollTop = $(this).scrollTop();
            if (scrollTop > 0) {
                $fdaTabs.addClass('modal-scrolled');
                $checkoutHeader.addClass('desktop-scrolled');
            } else {
                $fdaTabs.removeClass('modal-scrolled');
                $checkoutHeader.removeClass('desktop-scrolled');
            }
        }, dbtime));
    } else {
        $fdaInfo.scroll(_.debounce(function() {
            var scrollTop = $(this).scrollTop();
            if (scrollTop > 0) {
                $fdaInfo.addClass('modal-scrolled-content');
                $fdaTabs.addClass('modal-scrolled');
            } else {
                $fdaInfo.removeClass('modal-scrolled-content');
                $fdaTabs.removeClass('modal-scrolled');
            }
        }, dbtime));
    }

    // For fda report selection, 'current_tab' variable passed in from the view
    var $fdaMain = $('.fda-main');
    if (current_tab.length > 0) {
        if (current_tab === 'fda-main') {
            $fdaMain.removeClass('hide');
        } else {
            $fdaInfo.removeClass('hide');
            if (current_tab === 'fda-cs') {
                $('.fda-tab-cs').trigger('click');
            }
            if (current_tab === 'fda-ghr') {
                $('.fda-tab-ghr').trigger('click');
            }
        }
    } else {
        // Handle modal views
        $fdaMain.removeClass('hide');
    }

    function showOption(opt) {
        var $selectedTab = $('.fda-tabs [aria-controls='+opt+']');

        $selectedTab.trigger('click');
        $fdaMain.fadeOut(333, function() {
            $fdaInfo.fadeIn(333);
            $selectedTab.focus();
        });
    };

    $('.fda-main-options-option').click(function(event) {
        event.preventDefault();
        var whichTab = $(this).data('fda-option');
        showOption(whichTab);
    });

    $('.fda-tabs-tab').click(function() {
        $('.carrier-status-fda-container').animate({
            scrollTop: 0
        }, 250, 'swing');
    });
}


function initTabs() {
    'use strict';

    // constants
    // =========
    var IS_CURRENT_TAB_CLASS = 'is-current-tab';
    var IS_CURRENT_CONTENT_TAB = 'current-content';
    var TRANS_TIMING = 300;
    var KEYS = {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
    };

    // private functions
    // =================

    function getTabList($tab) {
        return $tab.closest('[role="tablist"]');
    }

    function getTabs($tab) {
        var $tabList = getTabList($tab);

        return $('[role="tab"]', $tabList);
    }

    function isTabListVertical($tab) {
        var $tabList = getTabList($tab);

        return $tabList.attr('aria-orientation') === 'vertical';
    }

    function switchTabs(e) {
        e.preventDefault();

        // Interacted tab or dropdown
        var $el = $(this);

        // Set current tab group
        var tabGroup = $el.closest('.tabs-group').attr('tab-group-tabs');
        var tabGroupSelector = '[tab-group-tab='+tabGroup+']';
        var tabId;

        // Remove current styling from all tabs
        // Also manage ARIA
        $(tabGroupSelector).not($el)
            .removeClass(IS_CURRENT_TAB_CLASS)
            .attr({
                'aria-selected': 'false',
                'tabindex': '-1'
            });

        // Add current styling to active tab
        // Also manage ARIA
        $el.addClass(IS_CURRENT_TAB_CLASS);
        $el.attr({
            'aria-selected': 'true',
            'tabindex': '0'
        });
        $el.focus();

        // Determine if tab or dropdown, and determine ID of content
        if ($el[0].hasAttribute('tabs-dropdown')) {
            tabId = $el.val();
        } else {
            tabId = $el.attr('aria-controls');
        }
        // Construct attribute for the content
        var tabContentSelector = '[tab-content='+tabGroup+'-'+tabId+']';

        // Hide and show content (fade out / fade in)
        $('[tab-group-content=' + tabGroup + ']').removeClass(IS_CURRENT_CONTENT_TAB);
        setTimeout(function () {
            $('[tab-group-content='+tabGroup+']').addClass('hide');
            $(tabContentSelector).removeClass('hide');
            setTimeout(function() {
                $(tabContentSelector).addClass(IS_CURRENT_CONTENT_TAB);
            }, 33);
        }, TRANS_TIMING);
    }

    function focusNextTab($tab) {
        var $tabs = getTabs($tab);
        var tabIndex = $tabs.index($tab);

        if (tabIndex + 1 < $tabs.length) {
            $tabs.eq(tabIndex + 1).focus();
        } else {
            $tabs.eq(0).focus();
        }
    }

    function focusPrevTab($tab) {
        var $tabs = getTabs($tab);
        var tabIndex = $tabs.index($tab);

        if (tabIndex - 1 >= 0) {
            $tabs.eq(tabIndex - 1).focus();
        } else {
            $tabs.eq($tabs.length - 1).focus();
        }
    }

    function focusTab(e) {
        var $tab = $(this);
        var key = e.which;

        if (isTabListVertical($tab)) {
            if (key === KEYS.down) {
                e.preventDefault();
                focusNextTab($tab);
            } else if (key === KEYS.up) {
                e.preventDefault();
                focusPrevTab($tab);
            }
        } else {
            if (key === KEYS.right) {
                focusNextTab($tab);
            } else if (key === KEYS.left) {
                focusPrevTab($tab);
            }
        }
    }
    // handle actual tabs
    $('body').unbind('click').on('click', '[tabs-tab]', switchTabs);

    // handle dropdowns instead of tabs
    // Note, they must be nested within '.tabs-group'
    $('body').on('change', '[tabs-dropdown]', switchTabs);

    // handle keyboard arrows
    $('body').on('keydown', '[tabs-tab]', focusTab);

    // set default tab, if one's detected
    $('[tab-group-tabs]').each(function() {
        if ($(this)[0].hasAttribute('tab-default-id')) {
            var defaultTab = $(this).attr('tab-default-id');
            var defaultGroup = $(this).attr('tab-group-tabs');
            $('[tab-group-tabs='+defaultGroup+'] [aria-controls='+defaultTab+']').trigger('click');
        }
    });

    $('#brca, #arsacs').addClass('current-content').removeClass('hide');
};



exports.initDrawers = initDrawers;
exports.fdaModalSetUp = fdaModalSetUp;
exports.initTabs = initTabs;