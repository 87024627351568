'use strict';

var _ = require('lodash');
var syncError = require('./syncError').syncError;
var gtm_trackRemoveFromCart = require('./gtm').trackRemoveFromCart;
const HTTP_404 = 404;

function Kit (el, eventEmitter) {
    this.$el = $(el);
    this.eventEmitter = eventEmitter;
    this.url = this.$el.data('kit-url');
    this.$input = $(this.$el.find('input'));
    this.$label = $(this.$el.find('label'));

    this.setInitialGiftValues();
    this.watchInput();
    this.enableRemoveButton();
};

Kit.prototype.clearGiftValues = function() {
    this.giftwrap = null;
    this.giftMessage = null;
    this.save();
};

Kit.prototype.setInitialGiftValues = function() {
    // check if in giftwrap page and set initial values accordingly
    if (this.$el.hasClass('giftwrap-kit')) {
        this.giftwrap = this.$el.find('[name="wrap"]:checked').val();
        this.giftMessage = this.$el.find('.js-message').val();
    } else {
        this.giftwrap = null;
        this.giftMessage = null;
    }
};

Kit.prototype.getInput = function() {
    return this.$el.find('input');
};

Kit.prototype.removeKit = function(e) {
    this.eventEmitter.emit('loading');
    if (e) {
        e.preventDefault();
    }
    var url = this.url;
    var el = this.$el;
    var _this = this;
    var payload = {};
    var eventLabel = this.$el.hasClass('js-ancestry-kit-item') ? 'ancestry_delete' : 'health_ancestry_delete';
    // make sure data-layer is in global scope
    window.dataLayer = window.dataLayer || [];
    // add event to data-layer
    window.dataLayer.push({
        'event': 'AnalyticsEvent',
        'eventCategory': 'store',
        'eventAction': 'quantity change click',
        'eventLabel': eventLabel
    });

    $.ajax({
        type: 'DELETE',
        url: url,
        data: payload,
        dataType: "json",
        error: function (data) {
            if (data.status == HTTP_404) {
                _this.eventEmitter.emit('loading:stop');
                _this.eventEmitter.emit('syncError');
            }
        }
    }).done(function(data) {
        el.remove();
        _this.eventEmitter.emit('kit:removed', data);
        gtm_trackRemoveFromCart(data);
    });
};

Kit.prototype.enableRemoveButton = function () {
    var _this = this;
    _this.$el.find('.js-remove-kit').one('click keydown', function(e) {
        var is_enter_key_pressed = e.type == 'keydown' && e.keyCode == '13';
        var is_space_key_pressed = e.type == 'keydown' && e.keyCode == '32';

        if(is_enter_key_pressed || is_space_key_pressed || e.type == 'click') {
            _this.removeKit(e);
        }
    });
};

Kit.prototype.watchInput = function() {
    var _this = this;
    this.$input.on('input propertychange', _.debounce(function() {
        _this.eventEmitter.emit('validate');
        _this.save();
    }, 500));
};

Kit.prototype.watchGiftOptions = function () {
    var _this = this;
    _this.$el.find('[name="wrap"]').on('click', _.debounce(function() {
        _this.giftwrap = _this.$el.find('[name="wrap"]:checked').val();
        _this.save();
    }, 0));

    var $giftTextarea = _this.$el.find('.js-message');
    $giftTextarea.on('input', _.debounce(function() {
        _this.giftMessage = $giftTextarea.val();
        _this.save();
    }, 500));
};

Kit.prototype.save = function() {
    var name = this.$input.val();
    var url = this.url;
    var _this = this;
    var giftwrap = this.giftwrap;
    var giftMessage = this.giftMessage;

    this.$el.addClass('js-saving');
    this.eventEmitter.emit('validate');

    $.ajax({
        type: 'PUT',
        url: url,
        data: {'name': name, 'gift_wrap': giftwrap, 'gift_message': giftMessage},
        error: function (data) {
            if (data.status == HTTP_404) {
                _this.eventEmitter.emit('loading:stop');
                _this.eventEmitter.emit('syncError');
            }
        }
    }).done(function() {
        _this.$el.removeClass('js-saving');
        _this.eventEmitter.emit('validate');
    });
};

Kit.prototype.scrollTo = function() {
    var scrollOffset = 36;
    var animateDuration = 333;
    $('html,body').animate({ scrollTop: this.$el.offset().top - scrollOffset }, animateDuration);
};

exports.Kit = Kit;
