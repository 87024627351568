'use strict';

// USAGE trackTotalRevenue:
// TTAM.module.optimizelyTracking.trackTotalRevenue(language, total);
// Where "language" is a string, e.g. 'en-us'
// and "total" is a string, e.g. '497.0500'
//
// USAGE trackKitRevenue:
// TTAM.module.optimizelyTracking.trackKitRevenue(kit);
// Where kit is an object, e.g. { 'price': '199.0', 'sku': 'US-KIT-PGS' }
//
// USAGE cartPageTracking:
// TTAM.module.optimizelyTracking.trackKitsInCart();

// private functions
// -----------------

function sendKitCount(ancestryKits, healthAncestryKits){
    // make sure optimizely is defined, and then send signal
    window.optimizely = window.optimizely || [];
    window['optimizely'].push({
        'type': 'user',
        'attributes': {
            'ancestryKitsInCart': ancestryKits,
            'healthAncestryKitsInCart': healthAncestryKits
        }
    });
}

function getKitsInCart(id){
    var counter = document.getElementById(id);
    var kits = counter ? counter.textContent : '0';  // default to zero
    return kits.trim();  // returns a string
}

function checkoutButtonClicked(){
    var ancestryKits = getKitsInCart('text-ancestry-kit-count');
    var healthAncestryKits = getKitsInCart('text-health-kit-count');
    sendKitCount(ancestryKits, healthAncestryKits);
}

function dollarsToCents(dollars){
    // convert string to number with unary plus operator...
    // developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus
    var value = +dollars;
    // check if value Not-a-Number with strict equality
    // because it treats NaN values as unequal to every other value -- including itself...
    // developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#Strict_equality_using
    if(value !== value){
        value = 0;
    }
    return (value * 100);
}

function trackTotalRevenue(language, total){
    // make sure optimizely is defined
    window.optimizely = window.optimizely || [];
    // record language-code
    window.optimizely.push(['setDimensionValue', 'language_code', language]);
    // recorder total-revenue
    window.optimizely.push([
        'trackEvent',
        'orderConfirmation',
        {
            'revenue': dollarsToCents(total)
        }
    ]);
}

function trackKitRevenue(kit){
    // make sure optimizely is defined
    window.optimizely = window.optimizely || [];
    // record SKU
    window.optimizely.push([
        'setDimensionValue',
        'product_type',
        kit.sku
    ]);
    // record kit-revenue
    window.optimizely.push([
        'trackEvent',
        'purchase_made',
        {
            'revenue': dollarsToCents(kit.price - kit.discount)
        }
    ]);
    // reset product-type
    // Optimizely requires this reset in order to record more than one kit
    window.optimizely.push([
        'setDimensionValue',
        'product_type',
        null
    ]);
}

function trackKitsInCart(){
    var buttons = document.querySelectorAll('.js-checkout-button');
    var length = buttons.length;
    for(var index = 0; index < length; index++){
        buttons[index].addEventListener('click', checkoutButtonClicked, false);
    }
}

// public API
// ----------

module.exports = {
    trackKitRevenue: trackKitRevenue,
    trackKitsInCart: trackKitsInCart,
    trackTotalRevenue: trackTotalRevenue
};
