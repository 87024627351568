'use strict'

// constants
// =========

var IS_ON_CANVAS_CLASS = 'is-open';
var IS_VEILED = 'is-veiled';


// private functions
// =================

function getCanvasClassName(size){
    return size ? IS_ON_CANVAS_CLASS + '-' + size : IS_ON_CANVAS_CLASS;
}

function getVeiledClassName(size){
    return size ? IS_VEILED + '-' + size : IS_VEILED;
}

function toggleVeil($veil, unveil){
    var size = $veil.data('veilRespondTo');
    var canvasClassName = getCanvasClassName(size);
    var veiledClassName = getVeiledClassName(size);
    var hidden = (typeof unveil !== 'undefined') ? unveil : $veil.hasClass(canvasClassName);

    // hide visible veils
    $('.veil').removeClass(canvasClassName);

    // show/hide veil
    // update aria-hidden attribute
    // broadcast custom event
    $veil.toggleClass(canvasClassName, !hidden)
        .attr('aria-hidden', (hidden ? 'true' : 'false'))
        .trigger('veil:toggled', hidden);

    // disable/enable document scrolling
    $('body').toggleClass(veiledClassName, !hidden);
}


// on document ready
// =================

function init () {
    $('.js-veil').on('click', function(event){
        var $toggle = $(event.currentTarget);
        var $veil = $('#' + $toggle.attr('aria-controls'));
        toggleVeil($veil);
    });

    $('.veil').on('veil:show', function(event){
        toggleVeil($(this), false);
    }).on('veil:hide', function(event){
        toggleVeil($(this), true);
    });
}

module.exports = init;
