'use strict';

var TTAM = {}; // Set up our namespace

// Require sitewide libraries
var _ = require('lodash');
require('./vendor');
require('./lib');
require('./cookie_notice.js');

Cookies.defaults = {
    secure: true
};

// Require page specific js
var pages = {
    cart: require('./cart'),
    payment: require('./payment'),
    review: require('./review'),
    receipt: require('./receipt'),
    refund: require('./refund'),
    replacement: require('./replacement'),
    giftwrap: require('./giftwrap'),
    upsell: require('./upsell'),
    auto_refund: require('./auto_refund'),
    renewal_refund: require('./renewal_refund'),
};

function router() {
    // Figure out what page we on and run its script.
    var main = $('.js-main');
    _.each(pages, function(pageInit, page) {
        if (main.hasClass(page)){
            //some modules export more than one function
            if(typeof(pageInit) === "function") {
                pageInit();
            } else {
                pageInit.init();
            }
        }
    });
}

function removePicker() {
    $('.picker input[type=text]').closest('.picker').removeClass('picker');
}

function footerToggle() {
    // following youPub js
    var HIDE_CLASS = 'hide';
    var IS_OPEN_CLASS = 'is-open';

    $('.js-toggle-region').on('click', function(event){
        var $toggler = $(event.currentTarget);
        var $region = $('#' + $toggler.attr('aria-controls'));
        var isOpen = $region.attr('aria-expanded') === 'false';
        // update UI based on the aria-expanded value
        $toggler.toggleClass(IS_OPEN_CLASS, isOpen);
        $region.toggleClass(HIDE_CLASS, !isOpen).attr('aria-expanded', (isOpen ? 'true' : 'false'));
    });
}

TTAM.init = function() {
    router();
    removePicker();
    footerToggle();
};

TTAM.module = {
    airprTracking: require('./airpr_tracking'),
    optimizelyTracking: require('./optimizely_tracking'),
    heap: require('./heap'),
    native_mobile: require('./native_mobile'),
};

module.exports = TTAM;
