"use strict";

/**
 * Returns the product type that customer should receive abandonment emails on. Prioritizes most
 * expensive product.
 *
 * @param {Object} kitCounts
 * @returns {String} product type
 */
function getAddToCartProduct(kitCounts) {
  if (kitCounts['total_health'] > 0) {
    return 'th';
  } else if (kitCounts['prepaid_subscription_kit'] > 0) {
    return 'sub';
  } else if (kitCounts['health'] > 0) {
    return 'ha';
  } else if (kitCounts['ancestry'] > 0) {
    return 'at';
  }

  return '';
}

function trackAddToCart(product) {
  if (
    !window.TTAM_GTM ||
    !window.TTAM_GTM.emailCapture
  ) {
    return;
  }

  window.TTAM_GTM.emailCapture.put({
    addToCartType: product,
  });
}

module.exports = {
  trackAddToCart: trackAddToCart,
  getAddToCartProduct: getAddToCartProduct
};
