'use strict';

var error_fields = require('./error_fields');
var tos_toggle = require('./tos_toggle');
var overlay = require('./overlay');
var _ = require('lodash');

//////////////////////////////////////////////////////////////////////////
// SECTION: COUNTRY SELECTOR SUPPORT
// Handles i18n billing. User picks a country, and the billing form
// displays relevant fields (eg. states list, zip code vs postal code).
//////////////////////////////////////////////////////////////////////////

var states_returned = true;
var labels_returned = true;

function isCountryChangeRequired(desired_country) {
    return $('.js-country-billing select').val() !== desired_country;
}

function listenForCountryChange () {
    $('.js-country-billing select').change(function () {
        var country = $('.js-country-billing select').val();
        if (country != "N/A") {
            changeCountry(country);
        }
    });
}

function switchToDefaultCountry () {
    // US intentionally left out of all lists, it is the default
    var localesWithSingleCountry = ['CA', 'GB'];
    var localesWithMultipleCountries = ['EU', 'INT'];

    var language_code = TTAM.LANGUAGE_CODE.split('-');
    var locale = language_code[1].toUpperCase();

    if (localesWithSingleCountry.indexOf(locale) >= 0) {
        changeCountryWithReset(locale, false);
    } else if (localesWithMultipleCountries.indexOf(locale) >= 0) {
        $('.js-country-billing select').val('US');
        changeCountry('US');
    }
}

function changeCountry (country, callBack) {
    changeCountryWithReset(country, true, callBack);
}

function changeCountryWithReset (country, clearErrors, callBack) {
    overlay.setOverlay('waiting');
    if (clearErrors) {
        error_fields.clearErrors();
    }

    // disable country selector while ajax calls are in progress
    $('.js-country-billing select').prop("disabled", true);

    labels_returned = false;
    ajaxCall(
        'labels', country, updateLabels, true,
        function() {
            labels_returned=true;
        },
        function() {
            if (states_returned && labels_returned){
                $('.js-country-billing select').prop("disabled", false);
                if (callBack) {
                    callBack();
                }
                overlay.setOverlay('ready');
            }
        }
    );

    // A country selection means that the state is no longer known,
    // which means the tax may no longer be known.
    // Update the text for the tos so that it stops showing a warning about sales tax.
    tos_toggle.configTosLabel(false);

    // For certain countries, we don't want states showing up
    var countriesWithoutStateField = ['GB'];
    if (countriesWithoutStateField.indexOf(country) >= 0) {
        $('#id_state').closest('.form-field').hide();
    } else {
        states_returned = false;
        ajaxCall(
            'states', country, updateStates, true,
            function() {
                states_returned = true;
            },
            function() {
                if (states_returned && labels_returned){
                    $('.js-country-billing select').prop("disabled", false);
                    if (callBack) {
                        callBack();
                    }
                    overlay.setOverlay('ready');
                }
            }
        );
    }
}

function updateLabels(data){
    var optional = " (optional)";
    if (data.required_fields.state){
        $('label[for=id_state]').text(data.required_fields.state);
        error_fields.updateErrorField('state', 'label', data.required_fields.state);
        error_fields.updateErrorField('state', 'required', true);
    } else if (data.optional_fields.state){
        $('label[for=id_state]').text(data.optional_fields.state + optional);
        error_fields.updateErrorField('state', 'label', data.optional_fields.state);
        error_fields.updateErrorField('state', 'required', false);
    }
    $('label[for=id_state]').text('Billing ' + $('label[for=id_state]').text());

    if (data.required_fields.postal_code){
        $('label[for=id_postal_code]').text(data.required_fields.postal_code);
        error_fields.updateErrorField('postal_code', 'label', data.required_fields.postal_code);
        error_fields.updateErrorField('postal_code', 'required', true);
    } else if (data.optional_fields.postal_code){
        $('label[for=id_postal_code]').text(data.optional_fields.postal_code + optional);
        error_fields.updateErrorField('postal_code', 'label', data.optional_fields.postal_code);
        error_fields.updateErrorField('postal_code', 'required', false);
    }
    $('label[for=id_postal_code]').text('Billing ' + $('label[for=id_postal_code]').text());

    labels_returned = true;
}

function updateStates(data){
    // event listeners may have been tied to state. Unbind them before updating state.
    unbindStateChangeListener();

    var $state = data.length > 0 ? rebuildStateAsSelect(data) : rebuildStateAsText();
    $state.closest('.form-field').show();

    states_returned = true;
}

function rebuildStateInput(input) {
    var state_template = _.template($('#payment-form-state-' + input).html());
    $('.state-input-container').empty().append(state_template);
    return $('#id_state');
}

function rebuildStateAsSelect(data) {
    var element = rebuildStateInput('select');

    // populate selector drop down
    for (var i = 0; i < data.length; i++) {
        var option = new Option(data[i].text, data[i].value);
        element.append(option);
    }

    element.parent().addClass("picker");
    listenForStateChange();
    return element;
}

function rebuildStateAsText() {
    var element = rebuildStateInput('text');
    element.parent().removeClass("picker");
    return element;
}

function unbindStateChangeListener() {
    $("[name=state]").unbind();
}

function listenForStateChange() {
    $("[name=state]").change(handleStateChange);
}

function handleStateChange () {
    tos_toggle.updateTosTextForState($("[name=state]").val());
}

function ajaxCall(apiName, country, successFunction, firstTry, returnFunction, checkAllAjaxReturned){
    $.ajax({
        type: 'GET',
        url: '/' + global.TTAM.LANGUAGE_CODE + '/'+ apiName +'/' + country + '/',
        error: function() {
            if (firstTry) {
                ajaxCall(apiName, country, successFunction, false, returnFunction);
            } else {
                returnFunction();
            }
        }
    })
        .done(function(e) {
            successFunction(e);
        })
        .always(function() {
            checkAllAjaxReturned();
        });
}

module.exports = {
    listenForCountryChange: listenForCountryChange,
    switchToDefaultCountry: switchToDefaultCountry,
    rebuildStateAsText: rebuildStateAsText,
    listenForStateChange: listenForStateChange,
    changeCountry: changeCountry,
    isCountryChangeRequired: isCountryChangeRequired,
    handleStateChange: handleStateChange,
};