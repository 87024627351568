'use strict';

var bt = require('./braintree');
var payment_selection = require('./payment_selection');
var country_selector = require('./country_selector');
var tos_toggle = require('./tos_toggle');
var _ = require('lodash');

function init () {
    tos_toggle.listenForTosToggle();
    var $body = $('.js-credit-card').closest('.payment-panel').find('.js-payment-method-body');
    $body.append($(_.template($('#billing-form-template').html(), {})));

    const has_saved_credit_card = TTAM.payment_methods.length && TTAM.payment_methods[0].is_credit_card;
    if (has_saved_credit_card) {
        tos_toggle.updateTosTextForState(TTAM.payment_methods[0].state);
    } else {
        tos_toggle.configTosLabel(TTAM.LANGUAGE_CODE === 'en-ca');
        $body.slideDown('fast');
    }
    bt.init();

    country_selector.listenForCountryChange();
    country_selector.switchToDefaultCountry();
    country_selector.listenForStateChange();

    payment_selection.enablePaymentMethodSelection();
    payment_selection.selectLoadedProfile();
}

module.exports = init;
