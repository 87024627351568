'use strict';

var split = require("shlex").split;

function parse_discount_info(discount_label) {
    /*
        Prepaid upsell discount label parsing utility.
        Expected Format:
          display="Thanksgiving Special" tag="prepaid-upsell" discount_price=15.00

        Parsing logic should be equivalent to
        store/store/templatetags/upsell_discounts.py
     */

    const PREPAID_MULTIKIT_DISCOUNT_LABEL_TOKEN = 'prepaid-upsell';
    let shlexed;
    try {
        shlexed = split(discount_label);
    } catch (error) {
        // Regular discount label (with an apostrophe).
        // (this is not a prepaid-upsell discount, just a regular label with an apostrophe)
        return {
            'has_discount': false,
            'label': discount_label,
            'discount_price': '',
        };
    }

    const tokens = shlexed.reduce(function(accumulator, pair) {
        const [key, ...value] = pair.split('=');
        accumulator[key] = value.join('=');
        return accumulator;
    }, {});
    const expected_keys = ['display', 'tag', 'discount_price'];
    const hasAllKeys = expected_keys.every(item => tokens.hasOwnProperty(item));
    if (!hasAllKeys) {
        const partialFormat = expected_keys.some(item => tokens.hasOwnProperty(item));
        return {
            'has_discount': false,
            'label': partialFormat ? '' : discount_label,
            'discount_price': '',
        };
    }

    const has_prepaid_tag = tokens['tag'] == PREPAID_MULTIKIT_DISCOUNT_LABEL_TOKEN;
    return {
        'has_discount': has_prepaid_tag,
        'label': has_prepaid_tag ? tokens['display'] : discount_label,
        'discount_price': has_prepaid_tag ? tokens['discount_price'] : '',
    };
}

function set_upsell_flags(item) {
    const UPSELL_PRICE_MAP = {
        "ancestry": TTAM.ancestry_upsell_price,
        "health": TTAM.premium_price,
    }
    let upsell_price = UPSELL_PRICE_MAP[item['alias']];
    let discount_price = item['discount_info']['discount_price'];
    item['discount_info']['is_upsell_inflated'] = discount_price > upsell_price;
}


exports.parse_discount_info = parse_discount_info;
exports.set_upsell_flags = set_upsell_flags;