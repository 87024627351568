'use strict';

var _ = require('lodash');
var parse_discount_info = require('./shlex').parse_discount_info;
var set_upsell_flags = require('./shlex').set_upsell_flags;
const $checkout_panel = $('.checkout-panel');
let scroll_handler = null;
let cartv2_emitter = null;
var $checkoutButton = $('.submit');
var $checkoutExpandButton = $('.js-expand-checkout-button');
var $payPalCheckoutButton = $('.js-paypal-button');
var cartv2_initKits = null;

function language_code(){
    var lc = TTAM.LANGUAGE_CODE.split('-');
    lc[1] = lc[1].toUpperCase();
    return lc.join('-');
}

function get_emptied_kit_list() {
    var kit_list = $('.cartv2-items');
    disable_listeners();
    kit_list.empty();
    return kit_list;
}

function update_cart(data) {
    disable_overlays();
    var kit_list = get_emptied_kit_list();
    if (data.kits.length > 0) {
        $.each(data.kits, function(key, item){
            item.discount_info = parse_discount_info(item.discount_label);
            set_upsell_flags(item);

            var kit_template = $('#cartv2-'+item.alias+'-template').html();
            var cart_item = $(_.template(kit_template, item));
            cart_item.find('.discount-currency').formatCurrency({region: language_code()});

            kit_list.append(cart_item);
        });

        $('#cart-section-panel-left-container').removeClass("empty");
        if (data.totals.items_discount) {
            $('.js-totals-subtotal').removeClass("hide-discount-info");
            $('.js-totals-savings').removeClass("hide-discount-info");
        } else {
            $('.js-totals-subtotal').addClass("hide-discount-info");
            $('.js-totals-savings').addClass("hide-discount-info");
        }

        cartv2_initKits();
    } else {
        $('#cart-section-panel-left-container').addClass("empty");
        $('.js-totals-subtotal').addClass("hide-discount-info");
        $('.js-totals-savings').addClass("hide-discount-info");
    }

    $('.js-totals-subtotal-numkits').text("Subtotal (" + data.kits.length + ")");
    $('.js-totals-subtotal-amount').text(data.totals.items_total).formatCurrency({region: language_code()});
    $('.js-totals-savings-amount').text(data.totals.items_discount).formatCurrency({region: language_code()});
    $('.js-totals-total-numkits').text("Total (" + data.kits.length + ")");
    $('.js-totals-total-amount').text(data.totals.items_total_with_discount).formatCurrency({region: language_code()});

    $checkoutButton.prop('disabled', data.kits.length == 0);
    $checkoutExpandButton.prop('disabled', data.kits.length == 0);
    $payPalCheckoutButton.prop('disabled', data.kits.length == 0);

    enable_listeners(cartv2_emitter);
    enable_overlays();
    cartv2_emitter.emit('loading:stop');
}

function enable_emitter(emitter) {
    emitter.on('cartv2:kit:operation', update_cart);
}

function disable_listeners() {
    $('.js-add-product').off();
    $('.js-remove-product').off();
    $('.js-upgrade-product').off();
}

function enable_listeners(emitter) {
    $('.js-add-product').on('click', function (e) {
        emitter.emit('loading');

        const $el = $(e.currentTarget);
        const alias = $el.data('alias').trim();
        const url = $el.data('kit-url');

        $.ajax({
            type: 'POST',
            url: url,
            data: {
                'alias': alias,
            },
            dataType: "json",
        }).done(function(data) {
            emitter.emit('cartv2:kit:operation', data);
        });
        return false;
    });

    $('.js-remove-product').on('click', function (e) {
        emitter.emit('loading');

        const $el = $(e.currentTarget);
        const url = $el.data('kit-url');

        $.ajax({
            type: 'DELETE',
            url: url,
            dataType: "json",
        }).done(function(data) {
            emitter.emit('cartv2:kit:operation', data);
        });
        return false;
    });

    $('.js-upgrade-product').on('click', function (e) {
        emitter.emit('loading');

        const $el = $(e.currentTarget);
        const url = $el.data('kit-url');

        $.ajax({
            type: 'POST',
            url: url,
            dataType: "json",
        }).done(function(data) {
            emitter.emit('cartv2:kit:operation', data);
        });
        return false;
    });
}

const collapse_mobile_checkout_panel = function () {
    $('.expanded-checkout-wrapper .horizontal-button, #checkout-panel-mobile-veil').off("click");
    $('.expand-checkout-wrapper').removeClass('expanded');
    $('.expanded-checkout-wrapper').removeClass('expanded');
    $('#checkout-panel-mobile-veil').hide();
};

function mobile_checkout_panel_event_handlers() {

    // ie. expand_mobile_checkout_panel
    const expand = function() {
        $('.expand-checkout-wrapper').addClass('expanded');
        $('.expanded-checkout-wrapper').addClass('expanded');
        $('#checkout-panel-mobile-veil').show();

        $('.expanded-checkout-wrapper .submit').val('Checkout with credit card');
        $('.expanded-checkout-wrapper .horizontal-button, #checkout-panel-mobile-veil').on("click", collapse_mobile_checkout_panel);
    };

    $('.js-expand-checkout-button, .expand-checkout-wrapper .horizontal-button').on("click", expand);
}

function sticky_checkout_for_mobile() {
    $checkout_panel
        .addClass('sticky-enabled')
        .removeClass('sticky-disabled')
        .css('width', '100%')
    ;
}

function sticky_checkout_for_desktop(offsetTop, outerWidth) {
    const disable_sticky = function () {
        $checkout_panel
            .addClass('sticky-disabled')
            .removeClass('sticky-enabled');
    };

    const enable_sticky = function() {
        $checkout_panel
            .addClass('sticky-enabled')
            .removeClass('sticky-disabled');
    };

    enable_sticky();
    $checkout_panel.css('width', outerWidth);

    const $cart_items = $('#cartv2-body');
    const cart_item_offset = $cart_items.offset().top;
    var scrollTop = $(window).scrollTop();

    scroll_handler = function() {
        const maxY = cart_item_offset + $cart_items.outerHeight();
        let posY = $checkout_panel.offset().top + $checkout_panel.outerHeight();

        if (posY >= maxY && $checkout_panel.css('position') === 'fixed') {
            scrollTop = $(window).scrollTop() - (posY - maxY);
            disable_sticky();
        } else if ($checkout_panel.css('position') === 'absolute' && $(window).scrollTop() < scrollTop) {
            enable_sticky();
        }
    };

    $(window).bind('scroll', scroll_handler);
}

function enable_checkout_panel() {
    const DESKTOP = 768;

    const offsetTop = $checkout_panel.offset().top;

    let isMobile = $(window).width() < DESKTOP;

    const sticky_checkout = function() {
        let wasMobile = isMobile;
        isMobile = $(window).width() < DESKTOP;

        if ($(window).width() < DESKTOP) {
            if (isMobile && !wasMobile) {
                $(window).unbind('scroll');
            }
            sticky_checkout_for_mobile();
        } else {
            collapse_mobile_checkout_panel();
            $checkout_panel.addClass('sticky-reset-for-calculation');
            $checkout_panel.css('width', 'unset');
            const outerWidth = $checkout_panel.outerWidth();
            $checkout_panel.removeClass('sticky-reset-for-calculation');
            sticky_checkout_for_desktop(offsetTop, outerWidth);
        }
    };

    sticky_checkout();
    $(window).resize(sticky_checkout);
}

function initialize_sliders () {
    $('.product-cards.slick-carousel').slick({
        arrows: false,
        infinite: false,
        centerMode: true,
        variableWidth: true,
        initialSlide: 1,
        mobileFirst: true,
        edgeFriction: 0,
    });
}

function disable_overlays() {
    $('.js-show-overlay').off();
    $('.js-hide-overlay').off();
    $('.learn-more-overlay').off();
    $('.learn-more-overlay-wrapper').off();
}

function enable_overlays() {
    const open_overlay = function (e) {
        const $target = $(e.currentTarget);
        const $overlay = $('#' + $target.data('overlay-id'));
        if ($overlay) {
            $overlay.addClass('is-open');
        }
        return false;
    };

    const $overlays = $('.learn-more-overlay');
    const close_overlays = function () {
        $overlays.removeClass('is-open');
        return false;
    };

    $('.js-show-overlay').click(open_overlay);
    $('.js-hide-overlay').click(close_overlays);
    $(document).on('keydown', function(event) {
        if (event.key == "Escape") {
           close_overlays();
        }
    });
    $('.learn-more-overlay').click(function(){
        close_overlays();
    });
    $('.learn-more-overlay-wrapper').click(function(e){
        e.stopPropagation();
    });
}

function process_checkout_panel_scrolling(caller) {
    if (!TTAM.cartv2 || !scroll_handler) return;
    scroll_handler();
}

function initialize(emitter, initKits) {
    if (!TTAM.cartv2) return;

    cartv2_emitter = emitter;

    enable_overlays();

    enable_listeners(emitter);
    enable_emitter(emitter);

    // add kits carousel
    initialize_sliders();

    // sticky checkout panels
    enable_checkout_panel();
    mobile_checkout_panel_event_handlers();

    cartv2_initKits = initKits;
}

exports.initialize = initialize;
exports.cartv2_scrolling = process_checkout_panel_scrolling;
