'use strict';

var $upgradeButton = $('.js-upgrade-button');
var $noUpgradeButton = $('.js-no-upgrade-button');
var $upgradeInputFlag = $('.js-upgrade [name=upgrade]');

function trackUpgradeClicks(e){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'AnalyticsEvent',
        'eventCategory': 'store',
        'eventAction': 'upgrade click',
        'eventLabel': e,
    });
}

// Upgrade button click handler
function handleUpgradeButtonClick(button, upsellValue) {
    button.on('click', function(event){
        trackUpgradeClicks('upgrade-button-clicked');
        $upgradeInputFlag.val(upsellValue);
        $.ajax({
            url: '/upgrade/health',
            type: 'POST',
            dataType: 'json',
            data: {
                'upgrade': upsellValue,
            }
        });
    });
}

// Toggle all reports modal
function toggleVeil(event){
    var veilId = $(event.currentTarget).attr('aria-controls');
    var veil = document.getElementById(veilId);
    var open = $(veil).hasClass('is-open');

    $(veil).toggleClass('is-open', !open).attr('aria-hidden', (open ? 'true' : 'false'));
    $('body').toggleClass('is-veiled', !open);
}


function init() {
    $('.js-toggle-veil').on('click', toggleVeil);
    handleUpgradeButtonClick($upgradeButton, 'true');
    handleUpgradeButtonClick($noUpgradeButton, 'false');
}

module.exports = init;
