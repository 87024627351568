'use strict';


//////////////////////////////////////////////////////////////////////////
// SECTION: SUBMIT BUTTON ACCESS (LOADING SCREEN)
//////////////////////////////////////////////////////////////////////////

var $submit_order_button = $('#submit-order-button');
var $loading_overlay = $('#loading-overlay');

function setOverlay(status) {
    if (status === 'on') {
        $loading_overlay.addClass('is-active');
        enableSubmitButton(false);
    } else if (status === 'off') {
        setTimeout(function(){
            $loading_overlay.removeClass('is-active');
        }, 0);
        enableSubmitButton(true);
    } else if (status === 'waiting') {
        $loading_overlay.addClass('is-active');
    } else if (status === 'ready') {
        setTimeout(function(){
            $loading_overlay.removeClass('is-active');
        }, 0);
    }
}

function enableSubmitButton(isEnabled) {
    $submit_order_button.prop('disabled', !isEnabled);
}

module.exports = {
    setOverlay: setOverlay,
    enableSubmitButton: enableSubmitButton,
};