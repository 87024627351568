'use strict';

// Set global libraries, don't go crazy with these.
global.Cookies = require('./cookies');

// Set up Tipped.
require('./tipped');
require('./tipped_setup');

// Set up Slick.
require('./slick');
