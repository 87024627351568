'use strict';


var overlay = require('./overlay');
var _ = require('lodash');

//////////////////////////////////////////////////////////////////////////
// SECTION: HANDLE TOS TOGGLING
// Toggling the Terms of Service box enables/disables the SUBMIT button.
//////////////////////////////////////////////////////////////////////////

var sales_tax_is_on = false;
var $tos_accept = $('#tos-accept');
var $tos_container = $('#tos-container');

function listenForTosToggle() {
    $tos_accept.on('change', handleTosToggled);
}

function isTosChecked () {
    return $tos_accept.is(':checked');
}

function handleTosToggled () {
    overlay.enableSubmitButton(isTosChecked());
}

function getTosLabelTemplateId(with_tax) {
    return with_tax ? '#tos-label-with-tax' : '#tos-label-without-tax';
}

function updateTosTextForState(state){
    // for state which charges sales tax, the tos text gets updated to reflect that.
    var SALES_TAX_STATES = ['HI', 'WV', 'SD'];
    var state_with_tax = SALES_TAX_STATES.indexOf(state) > -1;
    configTosLabel(state_with_tax);
}

function configTosLabel (with_tax) {
    with_tax = (
        (with_tax && TTAM.LANGUAGE_CODE === 'en-us') ||  // US users from certain states see sales tax
        (TTAM.LANGUAGE_CODE === 'en-ca')                 // All canada users see sales tax
    );
    if (with_tax === sales_tax_is_on) {
        return;
    }

    var tos_label_id = getTosLabelTemplateId(with_tax);
    var tos_template = _.template($(tos_label_id).html());
    $tos_container.empty().append(tos_template);
    $tos_accept = $('#tos-accept');  // requery after rebuilding template

    // enable the freshly-built checkbox's event listeners
    $tos_accept.on('change', handleTosToggled);

    // If the sales tax status has changed, then turn off the submit button.
    // The user must click the tos checkbox again to get it back
    overlay.enableSubmitButton(false);

    sales_tax_is_on = with_tax;
}

module.exports = {
    listenForTosToggle: listenForTosToggle,
    configTosLabel: configTosLabel,
    updateTosTextForState: updateTosTextForState,
};