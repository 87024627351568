'use strict';

// Make sure tooltip is a little smaller than the viewport.
var maxWidth = 300;
var windowWidth = $(window).width() - 10;
var w = windowWidth < maxWidth ? windowWidth : maxWidth;
var iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );

// Define new Tipped skin
$.extend(Tipped.Skins, {
    'ttam-light' : {
        fadeIn: 50,
        maxWidth: w,
        close: iOS ? true : false,
        behavior: iOS ? 'hide' : ''
    }
});

// Set the new skin as a default.
Tipped.setDefaultSkin('ttam-light');

// Create tooltips!
$(document).ready(function() {
    Tipped.create('.js-tooltip');
});
