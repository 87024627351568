'use strict';

var _ = require('lodash');

function trackEvent(action, label){
    // add event to data-layer
    window.dataLayer.push({
        'event': 'AnalyticsEvent',
        'eventCategory': 'store',
        'eventAction': action,
        'eventLabel': label
    });
}

function enableTOSCheck () {
    var $checkLabel = $('.js-accept-tos label');
    var $check = $('.js-accept-tos input');
    var $button = $('.js-submit');
    $button.addClass('button-disabled');
    $check.attr('checked', false);
    $check.change(function() {
        $checkLabel.removeClass('error')
        $button.toggleClass('button-disabled');
    });

    var submit = _.once(function _submit() {
        $button.addClass('button-disabled');
        $button.attr('disabled', 'disabled');
        $('.submit-form').submit();
    });

    $button.click(function(e) {
        if (!$check.prop('checked')){
            $checkLabel.addClass('error');
        } else {
            submit();
        }
        return false;
    });
}

function enableUpgradeSupport() {
    $('#toggle-upgrade').click(function(){
        var checked = $(this).is(':checked');
        trackEvent('upgrade checkbox click', checked ? 'check' : 'uncheck');
        $("#toggle-product-upgrade").submit();
        $(this).attr("disabled", true);
    });
}

function recordUpgradeView(){
    var $checkbox = $('#toggle-upgrade');
    var eligible = $checkbox.length; // is the upgrade-checkbox on the page?
    var checked = $checkbox.is(':checked'); // has user already upgraded?
    // add tracking event
    // if user is eligible for an upgrade
    // AND user has not already upgraded
    if(eligible && !checked){
        trackEvent('upgrade form view', 'upgrade form view');
    }
}

function init () {
    // make sure data-layer is in global scope
    window.dataLayer = window.dataLayer || [];
    enableTOSCheck();
    enableUpgradeSupport();
    recordUpgradeView();
}

module.exports = init;
