'use strict';

// USAGE trackPageview:
// TTAM.module.airprTracking.trackPageview(name, languageCode);
// Where "name" is a string, e.g. 'store_cart'
// and "languageCode" is a string, e.g. 'en-ca'

// private functions
// -----------------

function trackPageview(name, languageCode){
    // make sure data-layer is in global scope before sending eventData
    if(window.dataLayer){
        window.dataLayer.push({
            'event': 'gaEvent',
            'gaEventCategory': 'airpr',
            'gaEventAction': name + '_pageview',
            'gaEventLabel': languageCode,
            'gaEventValue': 0,
            'gaEventNonInteractionBool': true
        });
    }
}

// public API
// ----------

module.exports = {
    trackPageview: trackPageview
};
