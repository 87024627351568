'use strict';


//////////////////////////////////////////////////////////////////////////
// SECTION: FORM VALIDATION ERROR HANDLING (PRE-SUBMISSION)
// No point in submitting a form that is empty.
// Handles address data separate from credit card data, since Braintree.
//////////////////////////////////////////////////////////////////////////

var $payment_form = $('#payment-form')[0];

var $email_form = $('#email-form')[0];

var $profile_selector = $('.profile-selector')[0];

var ERROR_FIELDS = {
    'email': { 'hook': 'email', 'label': 'Email', 'required': true, },
    'profile': { 'hook': 'profile', 'label': 'Profile', 'required': true, },
    'first_name': { 'hook': 'first-name', 'label': 'First Name', 'required': true, },
    'last_name': { 'hook': 'last-name', 'label': 'Last Name', 'required': true, },
    'address': { 'hook': 'address', 'label': 'Address', 'required': true, },
    'city': { 'hook': 'city', 'label': 'City', 'required': true, },
    'postal_code': { 'hook': 'postal-code', 'label': 'Zip Code', 'required': true, },
    'state': { 'hook': 'state', 'label': 'State', 'required': true, },

    // the KEYS here should match values returned by braintree
    'number': { 'hook': 'card-number', 'label': 'Credit Card Number', 'required': true, },
    'cvv': { 'hook': 'cvv', 'label': 'CVV', 'required': true, },
    'expirationDate': { 'hook': 'expirationDate', 'label': 'Expiration Date', 'required': true, },
}

function updateErrorField(field, element, value) {
    ERROR_FIELDS[field][element] = value;
}

function clearError (field) {
    var $element = $('.js-braintree-' + field);
    $element.slideUp(400);
    $element.text('');
    $element.hide();
}

function clearErrors() {
    for(var key in ERROR_FIELDS) {
        clearError(ERROR_FIELDS[key]['hook']);
    }
}

function displayError (field, message) {
    var $element = $('.js-braintree-' + field);
    $element.hide();
    $element.text(message)
    $element.slideDown(400);
}

function displayErrors (fields) {
    fields.forEach(function (item) {
        var field = item['field'];
        if (field in ERROR_FIELDS) {
            var fieldInfo = ERROR_FIELDS[field];
            var message = fieldInfo['label'] + item['message'];
            displayError(fieldInfo['hook'], message);
        }
    });
}

function getAddressErrors () {
    var errors = [];

    var addressFields = ['first_name', 'last_name', 'address', 'city', 'postal_code'];
    addressFields.forEach(function(field) {
        if (ERROR_FIELDS[field]['required'] && $('#id_' + field).val().trim().length === 0) {
            errors.push({'field': field, 'message': ' cannot be empty'});
        }
    });

    // state takes special handling, since it can be a textbox, a select dropdown, or even not exist.
    if ($('#id_country').val() != 'GB' && ERROR_FIELDS['state']['required']) {
        var state = $('#id_state').val();
        var label = ERROR_FIELDS['state']['label'];
        if (state.length === 0) {
            errors.push({'field': 'state', 'message': ' cannot be empty'});
        } else if (state === 'N/A') {
            errors.push({'field': 'state', 'message': ' must be selected'});
        }
    }

    return errors;
}

function getPaymentErrors (err) {
    var fields = [];
    var message = '';
    if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
        fields.push.apply(fields, ['number', 'expirationDate', 'cvv']);
        message = ' must be supplied';
    } else if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
        fields.push.apply(fields, err.details.invalidFieldKeys);
        message = ' is invalid';
    }

    var errors = [];
    for (var i = 0; i < fields.length; i ++) {
        errors.push({'field': fields[i], 'message': message});
    }
    return errors;
}

function getEmailErrors () {
    var errors = [];
    var emailFields = ['email'];

    emailFields.forEach(function(field) {
        if (ERROR_FIELDS[field]['required'] && $('#id_' + field).val().trim().length === 0) {
            errors.push({'field': field, 'message': ' cannot be empty'});
        }
    });
    return errors;
}

function getProfileErrors () {
    var errors = [];
    var hasError = !$('input[name=profile_id]:checked', '#payment-form').val();
    if (hasError) {
        errors.push({'field': 'profile', 'message': ' cannot be empty'});
    }
    return errors;
}

function hasProfileError () {
    var errors = getProfileErrors();
    if (errors && errors.length) {
        displayErrors(errors);
        $profile_selector.scrollIntoView();
        return true;
    }
    return false;
}

function hasAddressErrors () {
    var errors = getAddressErrors();
    if (errors.length > 0) {
        displayErrors(errors);
        $payment_form.scrollIntoView();
        return true;
    }
    return false;
}

function hasPaymentErrors (err) {
    if (err) {
        var errors = getPaymentErrors(err);
        displayErrors(errors);
        $payment_form.scrollIntoView();
        return true;
    }
    return false;
}

function hasEmailErrors () {
    var errors = getEmailErrors();
    if (errors.length) {
        displayErrors(errors);
        $email_form.scrollIntoView();
        return true;
    }
    return false;
}

module.exports = {
    updateErrorField: updateErrorField,
    clearErrors: clearErrors,
    hasAddressErrors: hasAddressErrors,
    hasPaymentErrors: hasPaymentErrors,
    hasEmailErrors: hasEmailErrors,
    hasProfileError: hasProfileError,
};