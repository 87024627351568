'use strict';

var overlay = require('./overlay');
var bt = require('./braintree');
var error_fields = require('./error_fields');
var country_selector = require('./country_selector');
var tos_toggle = require('./tos_toggle');
var _ = require('lodash');

//////////////////////////////////////////////////////////////////////////
// SECTION: SWITCH BETWEEN PAYMENT METHODS
// Credit Card (Hosted Fields) vs PayPal
//////////////////////////////////////////////////////////////////////////

var $submit_order_button = $('#submit-order-button');
var $panels = $('.js-payment-method-panel');
var $payment_form = $('#payment-form')[0];
var $body_credit_card = $('.js-credit-card').closest('.payment-panel').find('.js-payment-method-body');
var $body_saved_payment = $('.js-saved-payment').closest('.payment-panel').find('.js-payment-method-body');
var $profile_selector = $('.profile-selector-div');

function populateBillingPanel($body, callBack) {
    $body_saved_payment.empty();
    $body_credit_card.empty();
    $body.append($(_.template($('#billing-form-template').html(), {})));
    bt.rebuild(function(){
        callBack();
    });
}

function selectLoadedProfile() {
    if ($profile_selector.length) {
        var url = new URL(window.location.href);
        var loaded_human = url.searchParams.get('human');
        $('#' + loaded_human).prop('checked', true);
    }
}

function addNewPaymentFormToCreditCard(turnOnDisplay) {
    if (!$body_credit_card.children().length) {
        populateBillingPanel($body_credit_card, function () {
            if (turnOnDisplay) {
                $body_credit_card.slideDown('slow');
            }
        });
        country_selector.listenForCountryChange();
        country_selector.listenForStateChange();
    } else {
        if (turnOnDisplay) {
            $body_credit_card.slideDown('slow');
        }
    }
    country_selector.handleStateChange();
}

var openHandler = {
    credit_card: function($panel) {
        $panel.addClass('is-active');
        addNewPaymentFormToCreditCard(true);
    },
    paypal_account: function($panel) {
        $panel.addClass('is-active');
        $panel.find('.js-payment-method-body').slideDown('slow');
        tos_toggle.configTosLabel(false);
    },
    saved_payment: function($panel) {
        $body_saved_payment.empty();
        addNewPaymentFormToCreditCard(false);
        $panel.addClass('is-active');
        $panel.find('.payment-panel__aside').addClass('full-width');
        $('.js-update-saved-payment-hook').show();
        tos_toggle.updateTosTextForState(TTAM.payment_methods[0].state);
    },
};

var closeHandler = {
    credit_card: function($panel) {
        $panel.find('.js-payment-method-body').slideUp('fast', function () {
            $panel.removeClass('is-active');
        });
    },
    paypal_account: function($panel) {
        $panel.find('.js-payment-method-body').slideUp('fast', function () {
            $panel.removeClass('is-active');
        });
    },
    saved_payment: function($panel) {
        $('.js-update-saved-payment-hook').hide();
        $panel.find('.payment-panel__aside').removeClass('full-width');
        $panel.find('.js-payment-method-body').slideUp('fast', function () {
            $panel.removeClass('is-active');
        });
    },
};

function clean(key) {
    return key.replace('-', '_');
}

function cleanSubmitButton() {
    $submit_order_button.removeClass().addClass('btn');
}

function switchPaymentMethod($selected_field) {
    error_fields.clearErrors();

    var selected_payment_method = $selected_field.data('payment-method');

    cleanSubmitButton();

    // disable and close all payment methods
    $panels.each(function(index, panel) {
        var $panel = $(panel);
        var payment_method = $panel.find('.js-payment-method-field').data('payment-method');
        if (payment_method && payment_method !== selected_payment_method) {
            closeHandler[clean(payment_method)]($panel);
        }
    });
    $panels.promise().done(function() {
        // enable and display selected payment method
        var $panel = $selected_field.closest('.js-payment-method-panel');
        openHandler[clean(selected_payment_method)]($panel);

        $payment_form.scrollIntoView();

        // enable submit button
        $submit_order_button.addClass(selected_payment_method + '-order-button');
    });
}

function enableSwitchPaymentMethod() {
    $('.js-payment-method-field input:radio[name=method]').change(function(e) {
        var $selected_field = $(e.target.closest('.js-payment-method-field'));
        switchPaymentMethod($selected_field);
    });
}

function copyToForm(form_data) {
    $('#id_first_name').val(form_data.first_name);
    $('#id_last_name').val(form_data.last_name);
    $('#id_address').val(form_data.address);
    $('#id_city').val(form_data.city);
    $('#id_state').val(form_data.state);
    $('#id_postal_code').val(form_data.postal_code);
    $('#id_country').val(form_data.country);
}

function updateSavedPayment () {
    cleanSubmitButton();

    var $panel = $('.js-saved-payment').closest('.payment-panel');
    if (!$body_saved_payment.children().length) {
        $('.js-update-saved-payment-hook').hide();
        $panel.find('.payment-panel__aside').removeClass('full-width');

        populateBillingPanel($body_saved_payment, function (){
            bt.getHostedFieldsInstance().focus('number');
        });
        $body_saved_payment.slideDown('slow', function () {
            // special handling -
            // the user has opted to use their saved credit card, but they have also opted to update it.
            // This means it is effectively a new credit card.
            $submit_order_button.addClass('credit-card-order-button');
        });

        var initFormCallback = function () {
            copyToForm(TTAM.payment_methods[0]);
            country_selector.listenForCountryChange();
            country_selector.listenForStateChange();
        };

        if (country_selector.isCountryChangeRequired(TTAM.TTAM.payment_methods[0].country)) {
            country_selector.changeCountry(
                TTAM.TTAM.payment_methods[0].country,
                function() {
                    initFormCallback();
                }
            );
        } else {
            initFormCallback();
        }
    }
}

function enableUpdateSavedPayment() {
    $('.js-update-saved-payment-hook').click(function (e) {
        e.preventDefault();
        updateSavedPayment();
    });
}

function enableSubmitSavedPayment() {
    $submit_order_button.click(function () {
        if (!$submit_order_button.hasClass('saved-payment-order-button')) {
            return false;
        }

        overlay.setOverlay('on');
        $('#payment-form').submit();
    });
}

function enablePaymentMethodSelection () {
    enableSwitchPaymentMethod();
    enableSubmitSavedPayment();
    enableUpdateSavedPayment();
}

module.exports.enablePaymentMethodSelection = enablePaymentMethodSelection;
module.exports.enableSubmitSavedPayment = enableSubmitSavedPayment;
module.exports.selectLoadedProfile = selectLoadedProfile;
