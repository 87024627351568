'use strict';

// Usage: JST(path)(ctx);

var _cache = {};
var _ = require('lodash');

function lazyCompile (template) {
    var compiled = null;

    return function compile (ctx) {
        if (compiled === null) {
            compiled = _.template(template);
        }
        return compiled(ctx);
    };
}

function JST (templatePath) {
    return _cache[templatePath];
}

JST.add = function(templatePath, templateString) {
    _cache[templatePath] = lazyCompile(templateString);
};

module.exports = JST;
