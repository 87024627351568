'use strict';

const GTM_EVENT_ADD_TO_CART = 'add_to_cart';
const GTM_EVENT_REMOVE_FROM_CART = 'remove_from_cart';
const GTM_EVENT_UPGRADE_IN_CART = 'upgrade_in_cart';
const GTM_EVENT_DOWNGRADE_IN_CART = 'downgrade_in_cart';

function parseCartContents(data) {
    var amounts = [];
    var skus = [];
    var quantities = [];
    for (var k = 0; k < data.kits.length; k++){
        var kit = data.kits[k];
        amounts.push(kit['discount_price']);
        skus.push(kit['sku']);
        quantities.push(kit['qty']);
    }

    return {
        'amounts': amounts.join('|'),
        'skus': skus.join('|'),
        'quantities': quantities.join('|')
    }
}

function parseKitData(data) {
    return data.kits.map(function(item, index) {
        return {
            index: index,
            item_id: item.item_id,
            item_name: item.item_name,
            currency: data.currency,
            price: item.discount_price,
            coupon: item.discount_program,
            discount: item.discount,
            item_brand: '23andMe',
            item_category: 'DNA Kits',
            item_category2: item.item_category2,
            item_category3: item.item_category3,
            quantity: 1
        };
    })
}

function trackCartEvent(data, event) {
    const payload = {
        event: event,
        ecommerce: {
            currency: data.currency,
            value: data.totals.items_total,
            items: parseKitData(data)
        }
    };
    if (TTAM.gtm_null_payload) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    }
    dataLayer.push(payload);
}

function trackCartState(data){
    const payload = parseCartContents(data);
    dataLayer.push(payload);
}

function trackAddToCart(data) {
    trackCartEvent(data, GTM_EVENT_ADD_TO_CART);
    trackCartState(data);
}

function trackRemoveFromCart(data) {
    trackCartEvent(data, GTM_EVENT_REMOVE_FROM_CART);
    trackCartState(data);
}

function trackUpgradeInCart(data) {
    trackCartEvent(data, GTM_EVENT_UPGRADE_IN_CART);
    trackCartState(data);
}

function trackDowngradeInCart(data) {
    trackCartEvent(data, GTM_EVENT_DOWNGRADE_IN_CART);
    trackCartState(data);
}

function trackQuantityChange(alias, event){
    const trackers = {
        'health': 'health_ancestry', 'ancestry': 'ancestry', 'prepaid': 'health_ancestry'
    };
    if (trackers[alias] === undefined) {
        return;
    }

    const label = trackers[alias] + '_' + event;

    // make sure data-layer is in global scope
    window.dataLayer = window.dataLayer || [];

    // add event to data-layer
    window.dataLayer.push({
        'event': 'AnalyticsEvent',
        'eventCategory': 'store',
        'eventAction': 'quantity change click',
        'eventLabel': label
    });
}

exports.trackAddToCart = trackAddToCart;
exports.trackRemoveFromCart = trackRemoveFromCart;
exports.trackUpgradeInCart = trackUpgradeInCart;
exports.trackDowngradeInCart = trackDowngradeInCart;
exports.trackQuantityChange = trackQuantityChange;
