'use strict';

// Tracking for Heap analytics
// ===========================

// private functions
// -----------------

const optimizely = {
    isLoaded: function() { return !!(window.optimizely && window.optimizely.get) },
    getCohortData: function() {
        if (!optimizely.isLoaded()) {
            return [];
        }

        const activeCampaigns = getObjectValues(
            window.optimizely.get('state').getCampaignStates() || {}
        ).filter(function(campaign) { return campaign.isActive; });

        const cohortData = activeCampaigns.map(function(campaign) {
            const data = {};
            const key = campaign.experiment && campaign.experiment.name ? campaign.experiment.name : 'none';
            const value = campaign.variation && campaign.variation.name ? campaign.variation.name : 'none';

            data[key] = value;

            return data;
        });

        return cohortData;
    },
};

const optimizelyEdge = {
    isLoaded: function() { return !!(window.optimizelyEdge && window.optimizelyEdge.get); },
    getCohortData: function() {
        if (!optimizelyEdge.isLoaded()) {
            return [];
        }

        const activeCampaigns = getObjectValues(
            window.optimizelyEdge.get('state').getActiveExperiments() || {}
        );

        const cohortData = activeCampaigns.map(function(campaign) {
            const data = {};
            const key = campaign.name ? campaign.name : 'none';
            const value = campaign.variation && campaign.variation.name ? campaign.variation.name : 'none';

            data[key] = value;

            return data;
        });

        return cohortData;
    },
};

function getObjectValues(obj) {
    var values = [];
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            values.push(obj[prop]);
        }
    }
    return values;
};

function formatPrice(price) {
    return Math.abs(parseFloat(price));
}

function getCohortData(){
    if (optimizely.isLoaded()) {
        return optimizely.getCohortData();
    }
    if (optimizelyEdge.isLoaded()) {
        return optimizelyEdge.getCohortData();
    }
    return [];
}

function trackCohortData(){
    if (window.heap) {
        var collection = getCohortData();
        $.each(collection, function(){
            window.heap.addUserProperties(this);
        });
    }
}

// public API
// ==========

module.exports = {
    trackCohortData: trackCohortData,
};
