'use strict';

var $refundButton = $('.button-continue');
var $refundFormFields = $('.auto-refund-input');
var $refundForm = $('.auto-refund-form');

function addErrorClass(formField) {
    formField.addClass('error');
    formField.prev('label').addClass('error');
}

function removeErrorClass(formField) {
    formField.removeClass('error');
    formField.prev('label').removeClass('error');
}

function scrollToField(field) {
    if (field) {
        var scrollOffset = 36;
        var animateDuration = 333;
        $('html,body').animate({ scrollTop: field.offset().top - scrollOffset }, animateDuration);
    }
}

function validateForm() {
    var isValid = true;
    $refundFormFields.each(function(){
        var _this = $(this);
        if (_this.val() === '') {
            if(isValid) {
                scrollToField(_this);
            }
            isValid = false;
            addErrorClass(_this);
        } else {
            removeErrorClass(_this);
        }
    });
    return isValid;
}

function enableFormSubmission() {
    $refundButton.click(function(e){
        e.preventDefault();
        if (validateForm()) {
            $refundForm.submit();
        }
    });
}

function init () {
    enableFormSubmission();
}

module.exports = init;