'use strict';


var payment_selection = require('./payment_selection');
var error_fields = require('./error_fields');
var overlay = require('./overlay');
var country_selector = require('./country_selector');

//////////////////////////////////////////////////////////////////////////
// SECTION: BRAIN TREE
//////////////////////////////////////////////////////////////////////////

var $submit_order_button = $('#submit-order-button');
var $submit_refund_button = $('#submit-refund-button');
var $email_form = $('#email-form');
var globalBraintreeInstance = null;
var globalHostedFieldsInstance = null;
var globalPaypalInstance = null;
var $profile_selector = $('.profile-selector');

function initBrainTreeGateway () {

    /****************************************************************
     *  Braintree v.3.22.2 - getting a nonce
     *
     *  Start by generating a braintree instance:
     *    braintree.client.create--->(braintree) instance
     *
     *  From here, each supported payment option can generate a nonce:
     *
     *   1) braintree.hostedFields.create(instance)--->hostedFieldsInstance
     *     onclick--->hostedFieldsInstance.tokenize()--->nonce
     *
     *     -- OR --
     *
     *   2) braintree.paypal.create(instance)--->paypalInstance
     *     onclick--->paypalInstance.tokenize()--->nonce
     ****************************************************************/

    braintree.client.create({
        authorization: TTAM.braintree_token,
    }, handleClientCreateComplete);
}

function handleClientCreateComplete (err, instance) {
    if (err) {
        console.log('braintree.client.create:error');
        console.log(err);
        return;
    }
    globalBraintreeInstance = instance;

    if ($submit_order_button.hasClass('free-cart-order-button')) {
        setupFreePaymentSubmitListener();
    } else {
        createHostedFields();
    }

    braintree.paypal.create({
        client: instance,
    }, handlePaypalCreateComplete);
}

function setupFreePaymentSubmitListener() {
    // Handle free service checkout orders. We don't want
    // hosted fields to be created for free orders since we
    // don't need their payment info.
    $('[data-payment-method=credit-card] > input[type=radio]').prop('checked', true);
    $submit_order_button.click(function () {
        error_fields.clearErrors();
        if (error_fields.hasAddressErrors()) {
            return false;
        }

        if (hasFormErrors()) {
            return false;
        }
        overlay.setOverlay('on');
        $('#payment-form').submit();
        overlay.setOverlay('ready');
    });
}

function setupPaypalSubmitListener() {
    $submit_order_button.click(function (e) {
        e.preventDefault();
        if (!$submit_order_button.hasClass('paypal-account-order-button')) {
            return false;
        }

        if (hasFormErrors()) {
            return false;
        }

        overlay.setOverlay('on');
        globalPaypalInstance.tokenize({ flow: 'vault', useraction: 'commit' }, handleTokenizePaypalComplete);
    });
}

function hasFormErrors() {
    return ($email_form.length && error_fields.hasEmailErrors()) ||
      ($profile_selector.length && error_fields.hasProfileError());
}

function clearSubmitListeners () {
    $submit_refund_button.off();
    $submit_order_button.off();
    payment_selection.enableSubmitSavedPayment();
}

function tearDownHostedFields(callBack) {
    overlay.setOverlay('waiting');
    if (globalHostedFieldsInstance) {
        globalHostedFieldsInstance.teardown(function (err) {
            if (err) {
                overlay.setOverlay('ready');
            } else {
                clearSubmitListeners();
                setupPaypalSubmitListener();

                createHostedFields(callBack);
            }
        });
    } else {
        clearSubmitListeners();
        setupPaypalSubmitListener();

        createHostedFields(callBack);
    }
}

function createHostedFields(callBack) {
    braintree.hostedFields.create({
        client: globalBraintreeInstance,
        styles: {
            input: {
                'font-size': '16px',
                'font-weight': '400',
                'font-family': "Atkinson Hyperlegible,Helvetica Neue,Helvetica,Roboto, sans-serif",
                padding: '10px 5px',
            },
            ':focus': {},
            '.invalid': {},
            '.valid': {}
        },
        fields: {
            number: {
                selector: '#card-number',
                placeholder: 'e.g. 1111 1111 1111 1111',
            },
            expirationDate: {
                selector: "#card-exp",
                placeholder: "MM/YY",
            },
            cvv: {
                selector: "#cvv",
                placeholder: 'e.g. 111',
            },
        },
    }, function (err, hostedFieldsInstance) {
        if (err) {
            // TODO - error handling
            return;
        }
        globalHostedFieldsInstance = hostedFieldsInstance;

        $submit_order_button.click(function () {
            if (!$submit_order_button.hasClass('credit-card-order-button')) {
                return false;
            }

            error_fields.clearErrors();
            if (error_fields.hasAddressErrors()) {
                return false;
            }

            if (hasFormErrors()) {
                return false;
            }

            overlay.setOverlay('on');
            hostedFieldsInstance.tokenize(handleTokenizeHostedFieldsComplete);
            return false;
        });

        $submit_refund_button.click(function () {
            hostedFieldsInstance.tokenize(handleTokenizeHostedFieldsComplete);
            return false;
        });

        if (callBack) {
            callBack();
        }
        overlay.setOverlay('ready');
    });
}

function handleTokenizeHostedFieldsComplete (err, payload) {
    if (error_fields.hasPaymentErrors(err)) {
        overlay.setOverlay('off');
        return;
    }

    $('#id_payment_method_nonce').val(payload.nonce);
    if (payload.details ) {
        $('#id_card_type').val(payload.details.cardType);
        $('#id_last_four').val(payload.details.lastFour);
    }
    $('#payment-form').submit();
}

function handlePaypalCreateComplete (err, paypalInstance) {
    if (err) {
        // TODO - error handling
        return;
    }

    globalPaypalInstance = paypalInstance;
    setupPaypalSubmitListener();
}

function handleTokenizePaypalComplete (err, payload) {
    if (err) {
        overlay.setOverlay('off');
        return;
    }

    $('#id_payment_method_nonce').val(payload.nonce);

    // Special state handling - since it can be a dropdown of specific values, and paypal could return a specific
    // state which does not match any of those values, we need to allow paypal to plug in an arbitrary state.
    // A textbox will do just fine.
    country_selector.rebuildStateAsText();

    $('#id_first_name').val(payload.details.firstName);
    $('#id_last_name').val(payload.details.lastName);
    $('#id_address').val(payload.details.billingAddress.line1);
    $('#id_city').val(payload.details.billingAddress.city);
    $('#id_state').val(payload.details.billingAddress.state);
    $('#id_postal_code').val(payload.details.billingAddress.postalCode);

    $('#payment-form').submit();
}

function getHostedFieldsInstance() {
    return globalHostedFieldsInstance;
}

module.exports.init = initBrainTreeGateway;
module.exports.getHostedFieldsInstance = getHostedFieldsInstance;
module.exports.rebuild = tearDownHostedFields;
