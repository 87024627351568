'use strict';

const EVENTS_TO_MOBILE = {
    STORE_HEALTH_UPGRADE_TRANSACTION_COMPLETE: 'STORE_HEALTH_UPGRADE_TRANSACTION_COMPLETE',
    STORE_KIT_TRANSACTION_COMPLETE: 'STORE_KIT_TRANSACTION_COMPLETE',
    STORE_CHIP_UPGRADE_TRANSACTION_COMPLETE: 'STORE_CHIP_UPGRADE_TRANSACTION_COMPLETE',
    STORE_TOTAL_HEALTH_UPGRADE_TRANSACTION_COMPLETE: 'STORE_TOTAL_HEALTH_UPGRADE_TRANSACTION_COMPLETE'
};

/**
 * send special event to native mobile so the app can catch it
 * @param message: the name of the message defined in
 * @param is_mobile_web_view: flag indicating this is a webview
 */
function send_to_native (message, is_mobile_web_view) {
    if (is_mobile_web_view) {
        // iOS
        if (window.webkit !== undefined) {
            try {
                const native = window.webkit.messageHandlers.nativeProcess;
                native.postMessage(message);
            } catch (error) {
                if (window.newrelic && window.newrelic.noticeError) {
                    const failure = new Error('iOSWebkit failure: ${error}');
                    window.newrelic.noticeError(failure);
                }
            }
        }

        // Android
        if (window.androidInterface !== undefined) {
            try {
                window.androidInterface.postMessage(message);
            } catch (error) {
                if (window.newrelic && window.newrelic.noticeError) {
                    const failure = new Error('AndroidInterface failure: ${error}');
                    window.newrelic.noticeError(failure);
                }
            }
        }
    }
}

function update_print_target(anchor_id) {
    if (window.androidInterface !== undefined) {
        const print_link = document.getElementById(anchor_id);
        if (print_link) {
            print_link.target = "_self";
        }
    }
}

module.exports = {
    send_to_native: send_to_native,
    EVENTS_TO_MOBILE: EVENTS_TO_MOBILE,
    update_print_target: update_print_target,
};
