'use strict';

var $reshipButton = $('.js-reshipment-submit-button');
var $submitUnverifiedButton = $('#submit_unverified_address');
var $submitVerifiedButton = $('#submit_verified_address');

function disableReshipButtonOnSubmit() {
    $submitUnverifiedButton.submit(function () {
        $reshipButton.addClass('button-disabled');
        $reshipButton.prop('disabled', true);
    });
    $submitVerifiedButton.submit(function () {
        $reshipButton.addClass('button-disabled');
        $reshipButton.prop('disabled', true);
    });
}

function init() {
    disableReshipButtonOnSubmit();
}

module.exports = init;
