'use strict';

function displayCartSyncError() {
    var errorModal = $('#js-cart-sync-error');
    errorModal.css('display', 'block');

    $('#js-cart-sync-error button').bind('click', function() {
        location.reload();
        $(this).unbind('click');
    });
}

function syncError () {
    displayCartSyncError();
}

exports.syncError = syncError;
