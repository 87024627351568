/*global doReload*/
'use strict';

var reload_timer;

function saveForm ($form) {
    var url = $form.attr('action');
    // $form.submit();
    $.post(url, $form.serialize());
}

function enableSurvey () {
    var $form = $('#survey-form');
    var answers = $form.find('input');

    $(answers).change(function() {
        saveForm($form);
        clearTimeout(reload_timer);
    });

    var $submit = $('.js-survey-submit');
    var $thankyou = $('.js-survey-thankyou');
    var $edit = $('.js-survey-edit');

    $submit.click(function(e) {
        e.preventDefault();
        $form.fadeOut(function() {
            $thankyou.fadeIn();
        });
    });

    $edit.click(function(e) {
        e.preventDefault();
        $thankyou.fadeOut(function() {
            $form.fadeIn();
        });
    });
}

// reload scheduled for orders in preliminary status
function scheduleReload() {
    if (doReload)
    {
        reload_timer = setTimeout(function (){
            location.reload();
        }, 5000);
    }
}

// hide order cancel button 1 hour after order creation
function hideOrderCancelButton() {
    cancellation_window_seconds -= 1;
    if (cancellation_window_seconds > 0) {
        setTimeout(hideOrderCancelButton, 1000);
    } else {
        $('.action-cancel-order').hide();
    }
}

function init () {
    enableSurvey();
    scheduleReload();
    hideOrderCancelButton();
}

module.exports = init;
